import parseQuery from "./util/parseQuery";
const query = parseQuery();
const AGENCY_SLUGS = ["tutordoctor", "9round"];

function handler(e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();

  const iframe = document.createElement("iframe");

  const params = {
    affiliateId: localStorage.affiliateId,
    sourceId: localStorage.sourceId,
    referralSlug: localStorage.referralSlug,
    utmSource: localStorage.utmSource,
    utmMedium: localStorage.utmMedium,
    utmCampaign: localStorage.utmCampaign,
    utmTerm: localStorage.utmTerm,
    utmContent: localStorage.utmContent,
    agencySlug: localStorage.agencySlug,
  };

  const body = Array.from(Object.entries(params))
    .filter(([_, v]) => v !== undefined)
    .map((pairs) => pairs.map(encodeURIComponent).join("="))
    .join("&");

  iframe.src = `https://js.skipio.com?${body}#/sign-up/${e.target.getAttribute(
    "data-skipio-plan-id"
  )}`;

  iframe.setAttribute("allowtransparency", "true");
  iframe.setAttribute(
    "style",
    `
        position: fixed;
        z-index:10000;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border:0;
        width: 100vw;
        height:100vh;
        height:-webkit-fill-available;
        background: #000a;
      `
  );

  document.body.appendChild(iframe);

  function close(event) {
    if (event.data === "close") {
      document.body.removeChild(iframe);
      window.removeEventListener("message", close);
    }
  }

  window.addEventListener("message", close, false);
}

// jQuery-esque event delegation
document.addEventListener(
  "click",
  (e) => {
    for (
      let target = e.target;
      target && target !== document;
      target = target.parentNode
    ) {
      // loop parent nodes from the target to the delegation node
      if (target.matches(`[data-skipio-plan-id]`)) {
        handler(e);
        break;
      }
    }
  },
  true
);

const storedQueryParams = {
  affiliateId: ["aid", "affiliateId"],
  sourceId: ["sid", "sourceId"],
  referralSlug: ["ref"],
  utmSource: ["utm_source"],
  utmMedium: ["utm_medium"],
  utmCampaign: ["utm_campaign"],
  utmTerm: ["utm_term"],
  utmContent: ["utm_content"],
};

Object.entries(storedQueryParams).forEach(([storedKey, queryKeys]) => {
  const queryParamValue = queryKeys.map((key) => query[key]).find(Boolean);
  if (queryParamValue) {
    localStorage[storedKey] = queryParamValue;
  }
});

const isPricingPage = window.location.pathname.split("/")[1] === "pricing";
const agencySlug = AGENCY_SLUGS.find(
  (agency) => isPricingPage && agency === window.location.pathname.split("/")[2]
);

if (agencySlug) {
  localStorage.agencySlug = agencySlug;
} else {
  localStorage.removeItem("agencySlug");
}
