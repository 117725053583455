export default function parseQuery(string = window.location.search) {
  const query: Record<string, any> = {};
  const pairs = string
    .substring(1)
    .split("&")
    .map((pair) => pair.split("="));

  for (let [key, value] of pairs) {
    if (!key) continue;
    query[decodeURIComponent(key)] = decodeURIComponent(value || "");
  }

  return query;
}
